import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Alert, AspectRatio, Box, Button, CloseButton, Flex, Image, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, SimpleGrid, Spinner, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { FiShoppingCart } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { ReservationVerificationModal } from '../components/ReservationVerificationModal';
import { useTrips } from "../contexts/TripContext";
import { fetchReservationData } from '../helpers/fetch';
import { ScrollToTop } from '../helpers/helpers';
import { verifyReservation } from '../helpers/reservationService';
import { addCartItem, generateDateOptions, getAllProducts, getOrders, getReservationCart, removeCartLineItem, updateCartLineItem } from '../helpers/storeService';

const categories = [
  "Food + Drink Deliveries",
  "Hospitality Services",
  "Beach Gear Rentals",
  "Watersports Deliveries",
  "Apparel + Accessories"
];

const Store = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paramReservationNumber } = useParams();
  const { allTripsData, updateAllTrips, selectedTrip, setSelectedTrip, isTripsContextLoaded } = useTrips();
  const [reservationLoaded, setReservationLoaded] = React.useState(false);
  const [productsLoaded, setProductsLoaded] = React.useState(false);
  const [dateOptions, setDateOptions] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [cart, setCart] = React.useState(null);
  const [checkoutLink, setCheckoutLink] = React.useState(null);
  const [orders, setOrders] = React.useState([]);
  const [ordersLoaded, setOrdersLoaded] = React.useState(false); 
  const [cartSize, setCartSize] = React.useState(0);
  const [deliveryFeeId, setDeliveryFeeId] = React.useState(null);
  const [hoursToCheckIn, setHoursToCheckIn] = React.useState(null);
  const { isOpen: isVerificationOpen, onOpen: onVerificationOpen, onClose: onVerificationClose } = useDisclosure();
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = React.useState(true);
  const [isWarningAlertVisible, setIsWarningAlertVisible] = React.useState(true);
  
  const toast = useToast();

  const { user } = useUser();
  //console.log('user', user)

  // Load Reservation Data from URL
  React.useEffect(() => {
    console.log('paramReservationNumber', paramReservationNumber);
    // Function to calculate hours to check-in
    const calculateHoursToCheckIn = (checkinDate, checkinTime) => {
      const checkinDateTime = new Date(`${checkinDate}T${checkinTime}`);
      const now = new Date();
  
      const checkinTimestamp = checkinDateTime.getTime();
      const nowTimestamp = now.getTime();
  
      const timeDifference = checkinTimestamp - nowTimestamp;
      return timeDifference / (1000 * 60 * 60);
    };
    // Function to get reservation data if user is not logged in, but a paramReservatioNumber is present
    async function getReservationData() {
      try {
        if (user || !paramReservationNumber) return; // Skip if reservation number is not available
        const response = await fetchReservationData(paramReservationNumber);
        const data = await response.json();
        //console.log('get reservation data response', data);
        if (response) {
          setSelectedTrip(data);
          const hoursDifference = calculateHoursToCheckIn(data.checkinDate, data.checkinTime);
          setHoursToCheckIn(hoursDifference);
          //console.log('Hours to Check-In:', hoursDifference);
        }
      } catch (error) {
        console.error("Error retrieving reservation data from param", error);
      } finally {
        setReservationLoaded(true);
      }
    }
    

    if (!user) {
      if (paramReservationNumber) {
        getReservationData();
      } else {
        onVerificationOpen();
      }
      return;
    }

    //console.log('paramReservationNumber', paramReservationNumber);
  
    updateAllTrips();

    if (paramReservationNumber && isTripsContextLoaded) {
      //console.log('selectedTrip', selectedTrip);
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(trip => trip.reservationNumber === paramReservationNumber);
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setReservationLoaded(true);
          const hoursDifference = calculateHoursToCheckIn(matchingTrip.checkinDate, matchingTrip.checkinTime);
          setHoursToCheckIn(hoursDifference);
          //console.log('Hours to Check-In:', hoursDifference);

        } else {
          // Optional: Handle the case where no matching trip is found (e.g., navigate to a "trip not found" page or show a message)
          setReservationLoaded(true);
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        const hoursDifference = calculateHoursToCheckIn(selectedTrip.checkinDate, selectedTrip.checkinTime);
        setHoursToCheckIn(hoursDifference);
        //console.log('Hours to Check-In:', hoursDifference);
        setReservationLoaded(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramReservationNumber, isTripsContextLoaded, user]);
  
  const handleVerificationModalSubmit = async (reservationNumber: string, checkinDate: string) => {
    const isValid = await verifyReservation(reservationNumber, checkinDate);
    if (isValid) {
      onVerificationClose();
      navigate(`/store/${reservationNumber}`);
    } else {
      toast({
        title: "Reservation Not Found",
        description: "No reservation found with these details. Please double check your inputs and try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Helper function to extract the query parameter
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  // Read the `category` query parameter
  const category = getQueryParam("category");
  const ownerPortal = getQueryParam("ownerportal");

  // Determine initial active category
  const initialCategory = React.useMemo(() => {
    return categories.includes(category) ? category : "Food + Drink Deliveries";
  }, [category]);

  const [activeCategory, setActiveCategory] = React.useState(initialCategory);

  React.useEffect(() => {
    // Update activeCategory if the URL parameter changes
    if (categories.includes(category)) {
      setActiveCategory(category);
    } else {
      setActiveCategory("Food + Drink Deliveries");
    }
  }, [category]);
  

  const renderCategoryButtons = () => {
    return categories
      .filter(category =>
        category === "Apparel + Accessories" || category === "Watersports Deliveries" || 
        products.some(product => product.node.tags.includes(category))
      )
      .map(category => (
        <Button
          key={category}
          onClick={() => setActiveCategory(category)}
          colorScheme='dmNavy'
          variant={activeCategory === category ? "solid" : "outline"}
          m={1}
          size={{base:'sm',md:'md'}}
        >
          {category}
        </Button>
      ));
  };

  const renderProducts = () => {
    // Filter products by category and exclude "Delivery Fee"
    const filteredProducts = activeCategory === "View All"
      ? products.filter(product => !product.node.title.includes("Delivery Fee"))
      : products.filter(product =>
          product.node.tags.includes(activeCategory) &&
          !product.node.title.includes("Delivery Fee")
        );
  
    // Handle "Apparel + Accessories" category explicitly
    if (activeCategory === "Apparel + Accessories") {
      return (
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" m="2">
          <AspectRatio ratio={1.3}>
            <Image
              src="../media/delmerchphoto.jpg"
              alt="Apparel + Accessories"
              objectFit="cover"
            />
          </AspectRatio>
          <Box px={4} pt={2} pb={4}>
            <Text fontWeight="bold" fontSize="1em" my="2">Apparel + Accessories</Text>
            <Text fontSize="0.8em" my="2">Add some style and fun to your Cape Cod getaway!</Text>
            <Button
              colorScheme="dmNavy" size="md" my="2" w={'100%'} mt={3}
              rightIcon={<ExternalLinkIcon />}
              onClick={() => window.open("https://deepbluecapecod.com/collections/products", "_blank")}
            >
              Visit the Store
            </Button>
          </Box>
        </Box>
      );
    }

    // Handle "Watersports Deliveries" category explicitly
    if (activeCategory === "Watersports Deliveries") {
      return (
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" m="2" position="relative">
          <AspectRatio ratio={1.3}>
            <Image
              src="../media/watersports.jpg"
              alt="Watersports Deliveries"
              objectFit="cover"
            />
          </AspectRatio>
          <Box px={4} pt={2} pb={4}>
            <Text fontWeight="bold" fontSize="1em" my="2">Watersports Equipment Rental + Free Delivery</Text>            
            <Flex gap={1} alignContent={'start'} alignItems='start'>
              <Text fontSize="0.8em" my="2">Rental Equipment Provided By:</Text>
              <Image
                src="../media/goosehummock.png"
                alt="Goose Hummock"
                objectFit="contain"
                height="80px"
              />              
            </Flex>
            {!ownerPortal && 
              <>
                <Text fontSize="0.7em" my="2" color={'dmOrange.500'}>* Before booking equipment please review the listing to confirm that watersports gear is not already provided at your vacation home.</Text>
                <Button
                  colorScheme="dmNavy" size="md" my="2" w={'100%'} mt={3}
                  rightIcon={<ExternalLinkIcon />}
                  onClick={() => window.open(`https://book.thisisdelmar.com/${selectedTrip.houseNumber}`, "_blank")}
                >
                  Review Property Listing
                </Button>
              </>
            }
            <Button
              colorScheme="dmNavy" size="md" my={1} w={'100%'}
              rightIcon={<ExternalLinkIcon />}
              onClick={() => window.open("https://www.themightyfish.com/pages/del-mar-vacation-rentals-goose-hummock-concierge", "_blank")}
            >
              Continue to Book
            </Button>
          </Box>
        </Box>
      );
    }

    // Render filtered products with ProductItem
    if (filteredProducts.length === 0) {
      return <Text>No products available for this category.</Text>;
    }
  
    return filteredProducts.map(product => (
      <ProductItem
        key={product.cursor}
        product={product}
        productCartItems={cart ? cart.lines.edges.filter(edge => edge.node.merchandise.id === product.node.variants.edges[0].node.id) : []}
        productQuantity={cart ? cart.lines.edges.filter(edge => edge.node.merchandise.id === product.node.variants.edges[0].node.id).reduce((acc, edge) => acc + edge.node.quantity, 0) : 0}
      />
    ));
  };
  

  // Initial Load of Store Products, Cart, and Orders
  React.useEffect(() => {
  // Define a flag to ensure we only set state if the component is still mounted
  let isComponentMounted = true;

  // Function to load store products
  async function getProducts() {
    try {
      //console.log('paramReservationNumber', paramReservationNumber);
      const reservationNumber = selectedTrip?.reservationNumber || paramReservationNumber;
      //console.log('reservationNumber', reservationNumber);
      if (!reservationNumber) return; // Skip if reservation number is not available
      const response = await getAllProducts(reservationNumber);
      console.log('response',response)
      if (isComponentMounted) {
        setProducts(response.data.products.edges);
        console.log('products response', response.data.products.edges);
        // Find the Delivery Fee product ID
        const deliveryFeeProduct = response.data.products.edges.find(product => product.node.title.includes("Delivery Fee"));
        if (deliveryFeeProduct) {
          setDeliveryFeeId(deliveryFeeProduct.node.variants.edges[0].node.id);
          //console.log('Delivery Fee Merchandise Id:', deliveryFeeProduct.node.variants.edges[0].node.id);
        }
      }
    } catch (error) {
      console.error("Error retrieving store products from Shopify", error);
    } finally {
      if (isComponentMounted) {
        setProductsLoaded(true);
      }
    }
  }

  // Function to get cart for reservation
  async function getCart() {
    try {
      const reservationNumber = selectedTrip?.reservationNumber || paramReservationNumber;
      if (!reservationNumber) return; // Skip if reservation number is not available
      const response = await getReservationCart(reservationNumber);
      //console.log('response', response)
      if (response.data && isComponentMounted) {
        setCart(response.data.cart);
        setCheckoutLink(response.data.cart.checkoutUrl);
        //console.log('get cart response', response.data.cart);
      }
    } catch (error) {
      console.error("Error retrieving cart", error);
    }
  }

  // Function to get existing orders for reservation
  async function getReservationOrders() {
    try {
      const reservationNumber = selectedTrip?.reservationNumber || paramReservationNumber;
      if (!reservationNumber) return; // Skip if reservation number is not available
      const response = await getOrders(reservationNumber);
      if (isComponentMounted) {
        setOrders(response);
        //console.log('get orders response', response);
      }
    } catch (error) {
      console.error("Error retrieving orders", error);
    } finally {
      if (isComponentMounted) {
        setOrdersLoaded(true);
      }
    }
  }

  // Execute all functions if selectedTrip is defined or paramReservationNumber exists
  if (selectedTrip || paramReservationNumber) {
    getProducts();
    getCart();
    getReservationOrders();
  }

  // Cleanup function to prevent state updates if the component unmounts
  return () => {
    isComponentMounted = false;
  };

}, [selectedTrip, paramReservationNumber]);



  // Generate Valid Date Options for Mid-Stay Cleaning
  React.useEffect(() => {
    if (productsLoaded && orders) {
      const checkinDate = selectedTrip?.checkinDate;
      const checkoutDate = selectedTrip?.checkoutDate;
      let dateOptions = generateDateOptions(checkinDate, checkoutDate);
  
      // Extract all cleaning dates from orders
      const orderedCleaningDates = orders.reduce((acc, order) => {
        const cleaningDates = order.orderProducts
          .filter(op => op.cleaningDate)
          .map(op => op.cleaningDate);
        return [...acc, ...cleaningDates];
      }, []);
  
      // Filter out the dates that have already been booked for cleaning
      dateOptions = dateOptions.filter(dateOption => 
        !orderedCleaningDates.includes(dateOption)
      );
  
      setDateOptions(dateOptions);
      //console.log('Updated dateOptions', dateOptions);
    }
  }, [selectedTrip, products, orders, productsLoaded]);  


  // Handle Checkout Link
  React.useEffect(() => {
    //console.log('cart', cart.lines.edges.length);
    if (cart && cart.lines.edges.length > 0) {
      setCartSize(cart.lines.edges.reduce((acc, edge) => acc + edge.node.quantity, 0));
    }
    if (cart && cart.checkoutUrl) {
      setCheckoutLink(cart.checkoutUrl);
    }
  }, [cart]);


  // Handle Checkout Link Click
  const handleCheckoutClick = async () => {

  console.log(cart.lines.edges)

  // Check if the cart only contains "Cleaning" or "Towels" items. These are excluded from delivery fee.
  const onlyCleaningOrTowelsInCart = cart?.lines.edges.every(line => {
    const merchandiseId = line.node?.merchandise?.id || '';
    const attribute = line.node?.attributes?.[0]?.['key'] || '';
    return (
      merchandiseId.includes("gid://shopify/ProductVariant/44519586922745") 
      || attribute === "date" 
      || attribute === "time"
      || line.node.merchandise.id === deliveryFeeId
    );
  });


    // Check to whether the delivery fee is already in the cart
    const deliveryFeeInCart = cart?.lines.edges.some(line => line.node.merchandise.id === deliveryFeeId);

    // Find the delivery fee line item in the cart (for update and remove operations)
    const deliveryFeeLineItem = cart?.lines.edges.find(line => line.node.merchandise.id === deliveryFeeId);
    const deliveryFeeNodeId = deliveryFeeLineItem?.node.id;

    //console.log('deliveryFeeInCart', deliveryFeeInCart);
    //console.log('deliveryFeeId', deliveryFeeId);

    if (hoursToCheckIn < 24 && !onlyCleaningOrTowelsInCart) {
      if (!deliveryFeeInCart) {
        try {
          await addCartItem(selectedTrip.reservationNumber, deliveryFeeId, 1);
          const updatedCart = await getReservationCart(selectedTrip.reservationNumber);
          setCart(updatedCart.data.cart);
          setCheckoutLink(updatedCart.data.cart.checkoutUrl);
  
          toast({
            title: "Delivery fee added to cart",
            description: "A delivery fee was added to your cart as your order is within 24 hours of check-in.",
            status: "info",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        } catch (error) {
          console.error("Error adding delivery fee to cart:", error);
          return; // Exit the function if adding the delivery fee fails
        }
      } else {
        try {
          const response = await updateCartLineItem(selectedTrip.reservationNumber, deliveryFeeNodeId, 1);
          setCart(response.data.cartLinesUpdate.cart);
          setCheckoutLink(response.data.cartLinesUpdate.cart.checkoutUrl);
  
          toast({
            title: "Delivery fee added to cart",
            description: "A delivery fee was added to your cart as your order is within 24 hours of check-in.",
            status: "info",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        } catch (error) {
          console.error("Error adding delivery fee to cart:", error);
          return; // Exit the function if adding the delivery fee fails
        }      
      }
    } else if (deliveryFeeInCart && onlyCleaningOrTowelsInCart) {
      try {
        const response = await removeCartLineItem(selectedTrip.reservationNumber, deliveryFeeNodeId);
        //console.log('removeCartLineItem response', response);
        setCart(response.data.cartLinesRemove.cart);
        setCheckoutLink(response.data.cartLinesRemove.cart.checkoutUrl);
        toast({
          title: "Delivery fee added to cart",
          description: "The delivery fee was removed because your cart only contains cleaning or towel items.",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } catch (error) {
        console.error("Error adding delivery fee to cart:", error);
        return; // Exit the function if adding the delivery fee fails
      } 
    }
  
    // Open the checkout link in a new tab
    window.open(checkoutLink, '_blank');
  
    // Delay navigation for 10 seconds
    setTimeout(() => {
      navigate(`/trips/${paramReservationNumber}`);
    }, 10000); // 10000 milliseconds = 10 seconds
  };
  
  


  // Product Item Component for Store Grid
  const ProductItem = ({ product, productCartItems, productQuantity }) => {

    const reservationNumber = selectedTrip?.reservationNumber || paramReservationNumber;

    const [cartItems, setCartItems] = React.useState(productCartItems || []);
    const [quantity, setQuantity] = React.useState(productQuantity || 0);
    
    const selectedDateRef = React.useRef<string | null>(null);
    const selectedTimeRef = React.useRef<string | null>(null);

    const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
    const handleDateChange = (e) => {
      selectedDateRef.current = e.target.value;
      //console.log('Selected Date:', selectedDateRef.current);
      updateButtonState();
    };
    
    const handleTimeChange = (e) => {
      selectedTimeRef.current = e.target.value;
      //console.log('Selected Time:', selectedTimeRef.current);
      updateButtonState();
    };
  
    const updateButtonState = () => {
      setIsButtonDisabled(!selectedDateRef.current || !selectedTimeRef.current);
    };

    const merchandiseId = product.node.variants.edges[0].node.id;
    const [processingCart, setProcessingCart] = React.useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    //console.log('product',product,'productQuantity',productQuantity,'productCartItems',productCartItems)

    React.useEffect(() => {
      setCartItems(productCartItems || []);
      setQuantity(productQuantity || 0);
    }, [productCartItems, productQuantity]);

    // Add to Cart
    const addToCart = async (cleaningDate?, cleaningTime?) => {
      //console.log('Adding product to cart:', merchandiseId, cleaningDate, cleaningTime);
      setProcessingCart(true);

      // Remove the selected date from available options if cleaningDate is provided
      if (cleaningDate) {
        const formattedCleaningDate = new Date(cleaningDate).toLocaleDateString('en-US', {
          month: 'long', // Full name of the month
          day: 'numeric', // Numeric day of the month
          year: 'numeric' // Four digit year
        });

        setDateOptions(prevOptions => prevOptions.filter(date => date !== formattedCleaningDate));
      }

      // Prepare attributes if cleaningDate and cleaningTime are provided
      let attributes = cleaningDate ? [{ key: "date", value: cleaningDate }] : [];

      if (cleaningTime) {
        attributes.push({ key: "time", value: cleaningTime });
      }

      try {
        // Call the addCartItem fetch function
        const response = await addCartItem(reservationNumber, merchandiseId, 1, attributes);

        setQuantity(1);

        //console.log('addCartItem response', response.data.cartLinesAdd.cart, "cart size", response.data.cartLinesAdd.cart.lines.edges.length)
        setCart(response.data.cartLinesAdd.cart);
        setCheckoutLink(response.data.cartLinesAdd.cart.checkoutUrl);

        // Show success toast notification
        toast({
          title: "Item added to cart",
          description: "Your item was successfully added to the cart.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } catch (error) {
        console.error("Error adding item to cart:", error);
        // Optionally, handle error by showing an error notification
        toast({
          title: "Error adding item to cart",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } finally {
        setProcessingCart(false);
      }
    }

    // Update Item in Cart
    const updateCartItem = async (itemId, quantity) => {
      //console.log('Updating cart line item:', itemId, 'to quantity:', quantity);
      const reservationNumber = selectedTrip?.reservationNumber || paramReservationNumber;
    
      try {
        const response = await updateCartLineItem(reservationNumber, itemId, quantity);
    
        // Assuming the response includes the updated cart data
        setCart(response.data.cartLinesUpdate.cart);
        
        //console.log('updateCartLineItem response', response.data.cartLinesUpdate.cart);
    
        // Show success toast notification
        toast({
          title: "Cart updated",
          description: "Your cart has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } catch (error) {
        console.error("Error updating cart line item:", error);
        toast({
          title: "Error updating cart",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    };
    
    const removeCartItem = async (itemId) => {
      //console.log('Removing cart line item:', itemId);
      const reservationNumber = selectedTrip?.reservationNumber || paramReservationNumber;
    
      try {
        const response = await removeCartLineItem(reservationNumber, itemId);

        // Assuming the response includes the updated cart data
        setCart(response.data.cartLinesRemove.cart);
        //console.log('removeCartLineItem response', response.data.cartLinesRemove.cart);
    
        // Show success toast notification
        toast({
          title: "Item removed",
          description: "The item has been removed from your cart.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } catch (error) {
        console.error("Error removing cart line item:", error);
        toast({
          title: "Error removing item",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    };

     // Function to handle quantity change
     const handleQuantityChange = (productId, newQuantity) => {
      //console.log('handleQuantityChange', productId, newQuantity);
      if (newQuantity === 0) {
        removeCartItem(productId);
        setQuantity(0);
      } else {
        updateCartItem(productId, newQuantity);
        setQuantity(newQuantity);
      }
    };


    const ReadMoreText: React.FC<{ description: string }> = ({ description }) => {
      const [isCollapsed, setIsCollapsed] = React.useState(true);
      const maxLength = 150; // Define your max length for the collapsed state
    
      const descriptionIsLong = description.length > maxLength;
    
      const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    
      return (
        <>
          <Text fontSize="0.8em" my="2">
            {isCollapsed && descriptionIsLong ? `${description.substring(0, maxLength)}...` : description}
          </Text>
          {/* Only render the toggle button if the description's length exceeds maxLength */}
          {descriptionIsLong && (
            <Button size="sm" variant="link" onClick={toggleCollapse} colorScheme="blue">
              {isCollapsed ? 'Read More' : 'Read Less'}
            </Button>
          )}
        </>
      );
    };

    // Helper function to extract numeric ID from the Shopify variant ID format
    const extractNumericId = shopifyVariantId => {
      const match = shopifyVariantId.match(/\d+$/);
      return match ? match[0] : null;
    };

    // Get the numeric ID for the current product variant
    const currentVariantNumericId = extractNumericId(product.node.variants.edges[0].node.id);

    // Find Quantity of Previously Ordered Products that Match the Current Variant ID
    const previouslyOrderedQuantity = orders.reduce((sum, order) => {
      // Filter orderProducts that match the current product variant's ID and sum their quantities
      const matchingQuantities = order.orderProducts
        .filter(op => op.shopifyVariantId === currentVariantNumericId)
        .reduce((acc, op) => acc + op.quantity, 0);
      return sum + matchingQuantities;
    }, 0);


    // Find Previously Booked Cleaning Dates for the Current Product Variant
    const previouslyBookedCleaningDates = orders.reduce<string[]>((dates, order) => {
      // Filter orderProducts that match the current product variant's ID
      const matchingCleaningDates = order.orderProducts
        .filter(op => op.shopifyVariantId === currentVariantNumericId && op.cleaningDate != null)
        .map(op => op.cleaningDate); // Collect non-null cleaning dates

      return dates.concat(matchingCleaningDates);
    }, []);

    // Prepare Previously Booked Cleaning Dates for Display
    const dateObjects = previouslyBookedCleaningDates.map(dateString => ({
      originalString: dateString,
      dateObject: new Date(dateString),
    }));
    dateObjects.sort((a, b) => a.dateObject.getTime() - b.dateObject.getTime());
    const sortedDateStrings = dateObjects.map(obj => obj.originalString);
    const uniquePreviouslyBookedCleaningDates = [...new Set(sortedDateStrings)];

    return (
      // JSX for each product item, including the NumberInput for quantity
      <Box key={product.cursor} maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" m="2">
        <AspectRatio ratio={1.3}>
          <Image
            src={product.node.images.edges[0].node.src}
            alt={product.node.title}
            objectFit="cover"
          />
        </AspectRatio>
        <Box px={4} pt={2} pb={4}>
          <Text fontWeight="bold" fontSize="1em" my="2" >
            {product.node.title}
          </Text>
          <ReadMoreText description={product.node.description} />
          <Text fontWeight="semibold" fontSize="0.9em" my="2">
            ${parseFloat(product.node.variants.edges[0].node.price.amount).toFixed(2)}
          </Text>

          {product.node.title.includes('Clean') && (
            <Button colorScheme="dmNavy" size="md" w={'100%'} onClick={onOpen} isDisabled={!dateOptions || dateOptions.length===0}>
              Add Cleaning Date
            </Button>
          )}

          {product.node.title.includes('Clean') ? (
            <>
              {/* Newly Added Cleaning Dates in Cart */}
              {cartItems && cartItems.map(item => (
                <Box key={item.node.id} display="flex" alignItems="center" justifyContent="space-between" my="2">
                  <Text fontSize="0.8em" fontWeight={'bold'}>{item.node.attributes[0]?.value}</Text>
                  <Button size="xs" onClick={() => 
                    {
                      removeCartItem(item.node.id);
                      // Add the removed date back into dateOptions
                      setDateOptions(prevOptions => {
                        const newOptions = [...prevOptions, item.node.attributes[0]?.value];
                        // Convert the date strings to Date objects for sorting
                        const sortedOptions = newOptions.map(dateStr => {
                          return {
                            original: dateStr,
                            dateObj: new Date(dateStr + " GMT-0000") // Append timezone to ensure consistent parsing
                          };
                        })
                        // Sort by the Date objects
                        .sort((a, b) => a.dateObj.getTime() - b.dateObj.getTime())
                        // Convert back to the original string format
                        .map(item => item.original);
                      
                        return sortedOptions;
                      });
                      
                    }
                  }>
                    X
                  </Button>
                </Box>
              ))}

              {/* Previously Booked Cleaning Dates */}
              {uniquePreviouslyBookedCleaningDates && uniquePreviouslyBookedCleaningDates.length > 0 && (
                <Flex mt={4} gap={1} flexDirection='column'>
                  <Text fontSize={'sm'} fontWeight='bold' color={'dmOrange.500'}>Previously Booked Cleaning Dates:</Text>
                  {uniquePreviouslyBookedCleaningDates.map((date, index) => (
                    <Text key={index} fontSize={'sm'} color={'dmOrange.500'}>
                      {date}
                    </Text>
                  ))}
                </Flex>
              )}

            </>

          ) : (
            <>
              {quantity === null || quantity === 0 ?
              <Button colorScheme="dmNavy" size="md" my="2" w={'100%'} onClick={() => addToCart()} isDisabled={product.node.title.includes('Clean')} isLoading={processingCart}>
                Add to Cart
              </Button>
              :
              <Flex direction={'column'} alignItems={'start'} gap={1}>
                <Box fontSize={'md'}>
                  Quantity in Cart:
                </Box>
                <NumberInput
                  min={0} w={'100%'}
                  value={quantity}
                  onChange={(valueString) => {
                    //console.log('cartItems change', cartItems);
                    handleQuantityChange(cartItems[0].node.id,parseInt(valueString));
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
              }
              {ordersLoaded && previouslyOrderedQuantity>0 &&
                <Text mt={2} fontSize={'sm'} color={'dmOrange.500'}>Previously Ordered: {previouslyOrderedQuantity}</Text>
              }
            </>
          )}
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg="blackAlpha.400" />
          <ModalContent>
            <ModalHeader>Select Cleaning Date</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text pb={3}>Select a date for your mid-stay cleaning. If you would like to schedule multiple mid-stay cleanings, add each one individually.</Text>
              {dateOptions &&
                <Select placeholder="Select date" onChange={handleDateChange}>
                  {dateOptions.map((date) => (
                    <option key={date} value={date}>{date}</option>
                  ))}
                </Select>
              }
              {dateOptions &&
                <Select placeholder="Select preferred start time" mt={3} onChange={handleTimeChange}>
                  <option value="09:00 AM - 12:00 PM">9 AM - 12 PM</option>
                  <option value="12:00 PM - 03:00 PM">12 PM - 3 PM</option>
                </Select>
              }
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="dmNavy" mr={3} onClick={() => {
                addToCart(selectedDateRef.current, selectedTimeRef.current);
                onClose();
              }} isDisabled={isButtonDisabled}>
                Confirm
              </Button>
              <Button variant="ghost" onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  return (
    <>
      {productsLoaded && (user && reservationLoaded || !user) ?
      <Flex textAlign="center" fontSize="xl" bg={"white"} minH={{base:0,md:'1100px'}} w={'100vw'}>
        <ScrollToTop />
        {paramReservationNumber ?
        <Flex
          bgColor="gray.50"
          width={{base:"100vw"}}
          flexDirection={'column'}
        >
          <Flex direction={"column"} alignItems={"start"} position="relative" textAlign={'start'} pt={{base:4, md:6}} px={{base:4, md:6}}>
            <Flex alignContent='center' alignItems="center" fontSize={{base:16, md:20}} mb={0} justifyContent={'space-between'} w={'100%'}>
              <Flex mb={3} mt={3}>
                <ChevronLeftIcon onClick={() => window.open(`/trips/${paramReservationNumber}`,'_self')} />
                <Text as="span" cursor="pointer" ml={2} lineHeight={'16px'} onClick={() => navigate(`/trips/${paramReservationNumber}`)} >
                  Back to My Trips
                </Text>
              </Flex>
              {/* Add Shopping Cart Icon and Count */}
              <Flex alignItems="center" gap={2} _hover={{cursor:'pointer', textDecoration:'underline'}}>
                {cart && cart.lines.edges.length > 0 && checkoutLink &&
                  <Button as="span" size={{base:'md', md:'lg'}} colorScheme={'dmOrange'} leftIcon={<FiShoppingCart />}
                    onClick={handleCheckoutClick}
                  >
                    Checkout ({cartSize})
                  </Button>
                }
              </Flex>
            </Flex>
          </Flex>

          {(selectedTrip && user || !user) ? (
            <Flex align="center" w={'100%'}>
              <Flex
                direction="column"
                justifyContent={'start'}
                alignItems={'center'}
                flex="1"
                pt={{base:2,md:2}}
                pb={{base:3,md:12}}
                px={{ base: "2", md: "6" }}
              >
                <Box
                  textAlign={{ base: "center", md: "center" }}
                  maxW={{ base: "full"}}
                  mx={{ base: "3", md: "auto" }}
                >                  
                  {isSuccessAlertVisible && 
                    <Alert status="success"
                      fontSize={{ base: "md", md: "md" }}
                      textAlign={{ base: "left", md: "left" }} fontWeight={'500'}
                      mb={{base:3,md:3}} px={{base:4, md:4}} maxW={{base:'sm',md:'full'}}
                    >
                      <Box flex="1">
                        Make your vacation extra relaxing with our extra options. Complete your order at least 24 hours before check-in time, and we'll deliver your items on your day of arrival with free shipping. Orders can be modified up to 24 hours prior to check-in. After that, all sales are final.
                      </Box>
                      <CloseButton
                        onClick={() => setIsSuccessAlertVisible(false)}
                      />
                    </Alert>
                  }
                  {hoursToCheckIn < 24 && isWarningAlertVisible &&
                    <Alert status="warning"
                      fontSize={{ base: "md", md: "md" }}
                      textAlign={{ base: "left", md: "left" }} fontWeight={'500'}
                      mb={{base:3,md:3}} px={{base:4, md:4}} maxW={{base:'sm',md:'full'}} gap={2}
                    >
                      <Box flex="1">
                        It is currently less than 24 hours to your check-in time. A $50 last minute delivery fee will be added to your order when you check out. This delivery fee does not apply to mid-stay cleaning or towel purchases.
                      </Box>
                      <CloseButton
                        onClick={() => setIsWarningAlertVisible(false)}
                      />
                    </Alert>
                  }

                  {/* 12/11/2024: New Category-Based Product Rendering Code */}
                  <Flex wrap="wrap" justifyContent="start" mb={4}>
                    {renderCategoryButtons()}
                    <Button
                      onClick={() => setActiveCategory("View All")}
                      colorScheme={"dmNavy"}
                      variant={activeCategory === "View All" ? "solid" : "outline"}
                      m={1} size={{base:'sm',md:'md'}}
                    >
                      View All
                    </Button>
                  </Flex>
                  {productsLoaded ? (
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
                      {renderProducts()}
                    </SimpleGrid>
                  ) : (
                    <Spinner size="xl" />
                  )}
                  {/* END 12/11/2024: New Category-Based Product Rendering Code */}

                  {/* Original Product Rendering Code
                  <SimpleGrid columns={{ base: 1, sm: 1, md: 3, lg: 4, xl: 5 }} spacing={0} pb={{ base: 1, md: 4 }}>
                    {products && products
                      .filter(product => !product.node.title.includes("Delivery Fee"))
                      .map((product) => (
                        <ProductItem 
                          key={product.cursor} 
                          product={product} 
                          productCartItems={cart ? cart.lines.edges.filter(edge => edge.node.merchandise.id === product.node.variants.edges[0].node.id) : []}
                          productQuantity={cart ? cart.lines.edges.filter(edge => edge.node.merchandise.id === product.node.variants.edges[0].node.id).reduce((acc, edge) => acc + edge.node.quantity, 0) : 0}
                        />
                    ))}
                  </SimpleGrid>
                  */}
                  <Flex alignItems="center" gap={2} _hover={{cursor:'pointer', textDecoration:'underline'}} w={'100%'} justifyContent={'center'}>
                    {cart && cart.lines.edges.length > 0 && checkoutLink &&
                      <Button size={'lg'} fontSize="lg" colorScheme={'dmOrange'} mt={4}
                        onClick={handleCheckoutClick}
                      >
                        Continue to Checkout ({cart.lines.edges.reduce((acc, edge) => acc + edge.node.quantity, 0)} items)
                      </Button>
                    }
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            ) : (
            <>
              <Flex justifyContent={'center'} w={'100%'} textAlign='center' pt={{base:3, md:12}} pb={2}>No extra options available.</Flex>
              <Link
                href="https://book.thisisdelmar.com/"
                target="_blank"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-orange-pill"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={'90vw'}
                  boxShadow="base"
                  my={12}
                >
                  Explore more homes
                </Button>
              </Link>
              <Link
                href="/"
                w={{ base: "100%", lg: "auto" }}
                className="no-underline"
              >
                <Button
                  className="dm-button-navy-outline"
                  w={{ base: "100%", lg: "300px" }}
                  maxW={'90vw'}
                  boxShadow="base"
                  mb={12}
                >
                  Back to Home
                </Button>
              </Link>
            </>
            )
          }

        </Flex>
        : reservationLoaded ?
        <Flex w={"100%"} h={'80vh'}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Text mb={6}>No Matching Reservation Found</Text>
          <Flex gap={4} flexDirection={{base:'column', md:'row'}} w={{ base: "100%", lg: "auto" }}>
            <Link
              href="/trips"
              w={{ base: "100%", lg: "auto" }}
              className="no-underline"
            >
              <Button
                className="dm-button-orange-pill"
                w={{ base: "100%", lg: "300px" }}
                maxW={'90vw'}
                boxShadow="base"
              >
                Back to My Trips
              </Button>
            </Link>
            <Link
              href='/'
              target="_self"
              w={{ base: "100%", lg: "auto" }}
              className="no-underline"
            >
            <Button
              className="dm-button-navy-outline"
              w={{ base: "100%", lg: "300px" }}
              maxW={'90vw'}
              boxShadow="base"
              onClick={() => window.open('/','_self')}
            >
              Start a New Search
            </Button>
            </Link>
          </Flex>
        </Flex>
        :
        <Flex w={'100%'} h={'90vh'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      }
      </Flex>
      :
      <Flex w={'100%'} h={'90vh'} justifyContent={'center'} alignItems={'center'} gap={4}>
        <Spinner />
        <Text>Loading Store Products...</Text>
      </Flex>
      }

      <ReservationVerificationModal isOpen={isVerificationOpen} onClose={onVerificationClose} onSubmit={handleVerificationModalSubmit} />

      {user && isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};
export { Store };

