import { Icon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Spinner, Tooltip } from "@chakra-ui/react";
import { addDays, format } from "date-fns";
import { useCallback, useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { MAX_END_DATE } from "../../../helpers/constants";
import { fetchHouseDetails } from "../../../helpers/fetch";
import {
  generateReservationQuote,
  searchAvailability,
} from "../../../helpers/guestportalfetch";

const baseUrl = config.baseUrl;

function AvailabilityCalendar({ listingId, modalClose, houseNumber }) {
  const { globalCheckIns } = useContext(SearchResultsContext);
  const [houseCalendar, setHouseCalendar] = useState(null);
  const [reservationQuote, setReservationQuote] = useState(null);
  const [date, setDate] = useState<string>("");
  const [start, setStart] = useState<Date>(null);
  const [end, setEnd] = useState<Date>(null);
  const [price, setPrice] = useState(0);
  const [fees, setFees] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const calendarStart = format(addDays(new Date(), 1), "yyyy-MM-dd");
  const calendarEnd = MAX_END_DATE;

  const { houseData, setHouseData, setContextDateRange, setGuests } =
    useContext(SearchResultsContext);

  const navigate = useNavigate();

  useEffect(() => {
    searchAvailability({ listingId, calendarStart, calendarEnd })
      .then((data) => {
        setHouseCalendar(data);
      })
      .catch((err) => console.error(err));
  }, [listingId, calendarStart, calendarEnd]);

  useEffect(() => {
    if (start && end) {
      const startString = format(start, "yyyy-MM-dd"); // Formats as 'YYYY-MM-DD'
      const endString = format(end, "yyyy-MM-dd"); // Formats as 'YYYY-MM-DD'

      //console.log("startString", startString, "endString", endString);

      generateReservationQuote({
        listingId,
        checkInDateLocalized: startString,
        checkOutDateLocalized: endString,
        guestsCount: 2,
      })
        .then((data) => {
          //console.log("quote", data);
          if (
            data.rates &&
            data.rates.ratePlans &&
            data.rates.ratePlans.length > 0
          ) {
            let nightlyRates =
              data.rates.ratePlans[0].ratePlan.money.fareAccommodation;
            let baseFees = data.rates.ratePlans[0].ratePlan.money.totalFees;
            let baseTotal =
              data.rates.ratePlans[0].ratePlan.money.fareAccommodation;
            let baseTaxes = data.rates.ratePlans[0].ratePlan.money.totalTaxes;
            setPrice(Math.round(nightlyRates));
            setFees(Math.round(baseFees));
            setTaxes(Math.round(baseTaxes));
            setReservationQuote(data);
            setContextDateRange([start, end]);
            setGuests(2);
          } else {
            setPrice(null); // Use null value to indicate no ratePlans returned
          }
        })
        .catch((err) => console.error(err));

      //console.log("Quote",quote);
    }
  }, [start, end]);

  const [houseLoading, setHouseLoading] = useState(true);

  const fetchHouseData = useCallback(async (num) => {
    setHouseLoading(true);
    try {
      //console.log("Fetching data for", houseNumber);
      const params = { houseNumber: num };

      const response = await fetchHouseDetails(params);

      if (!response.ok) {
        throw new Error(`fetchHouseDetails Error! Status: ${response.status}`);
      }

      const returnedHouseData = await response.json();
      setHouseData(returnedHouseData.results);
    } catch (error) {
      console.error("Error fetching house data from DB:", error);
    } finally {
      setHouseLoading(false);
      //console.log("returnedHouseData", houseData);
    }
  }, []);

  useEffect(() => {
    if (!houseData && houseNumber) {
      fetchHouseData(houseNumber);
    }
  }, [houseNumber, houseData, fetchHouseData]);

  // Transform the data received from the API
  function transformResponse(response) {
    return response.data.days.map((day) => {
      let date = new Date(day.date);
      return {
        startDate: date,
        booked: day.status === "available" ? 0 : 1,
        minDays: day.minNights,
        price: day.price,
        cta: day.cta,
        ctd: day.ctd,
      };
    });
  }

  const [guestyData, setGuestyData] = useState([]);
  const [startDatePicker, endDatePicker] = dateRange;
  const [bookedDates, setBookedDates] = useState([]);
  const [datesNotOkayToCheckIn, setDatesNotOkayToCheckIn] = useState([]);
  const [datesNotOkayToCheckInEpoch, setDatesNotOkayToCheckInEpoch] = useState(
    []
  );
  const [datesToGrayOut, setDatesToGrayOut] = useState([]);
  const [datesToGrayOutEpoch, setDatesToGrayOutEpoch] = useState([]);
  const [bookedAndNotOkayToCheckOutDates, setBookedAndNotOkayToCheckOutDates] =
    useState([]);
  const [currentlyShowing, setCurrentlyShowing] = useState("checkIn");
  const [firstAvailableCheckIn, setFirstAvailableCheckIn] = useState(null);

  // This is to adjust the Guesty date data if needed to make sure it blanks out the right dates i the datepicker
  const hoursOffset = new Date().getTimezoneOffset() / 60;

  useEffect(() => {
    if (houseCalendar) {
      // Get the Guesty data on initial page load (should only do this once, all other changes to datepicker will be done via state changes)
      const guestyDataRaw = houseCalendar;
      // Transform the data to get rid of fields we don't need and alter dates if needed, saves a COPY to be used for Check Outs later
      const guestyDataTransformed = transformGuestyData(
        guestyDataRaw,
        hoursOffset
      );
      setGuestyData([...guestyDataTransformed]);
      // Save the dates that are booked to make them a different style/color in the datepicker
      const getBookedDates = guestyDataTransformed
        .filter((date) => date.status === "booked")
        .map((date) => Date.parse(date.startDate));
      setBookedDates(getBookedDates);
      // Add checkInOkay = true/false field to each date
      const guestyDataCheckInsProcessed = processCheckIns(
        guestyDataTransformed
      );
      // Find the first available check in date
      const firstAvailableCheckIn = guestyDataCheckInsProcessed.find(
        (date) => date.checkInOkay === true
      );
      setFirstAvailableCheckIn(firstAvailableCheckIn);
      //console.log('firstAvailableCheckIn',firstAvailableCheckIn);
      // Create an array of all dates that have checkInOkay = false, then grab the startDate from each of them so it's just an array of dates for the datepicker
      const badCheckInDates = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => date.startDate);
      const badCheckInDatesEpoch = guestyDataCheckInsProcessed
        .filter((date) => !date.checkInOkay && date.startDate)
        .map((date) => Date.parse(date.startDate));
      // Set that array of dates to be grayed out
      setDatesToGrayOut(badCheckInDates);
      setDatesToGrayOutEpoch(badCheckInDatesEpoch);
      // (And save that array of dates so we can gray them again after user clicks an end date)
      setDatesNotOkayToCheckIn(badCheckInDates);
      setDatesNotOkayToCheckInEpoch(datesNotOkayToCheckInEpoch);

      setIsLoading(false);
    }
  }, [houseCalendar]);

  function renderDayContents(day, date) {
    let tooltipText = "";
    const dateEpoch = Date.parse(date);
    const today = new Date();
    const lastDateAvailable = new Date(
      globalCheckIns[globalCheckIns.length - 1]
    );

    if (currentlyShowing === "checkIn") {
      if (bookedDates.includes(dateEpoch)) {
        tooltipText = "Unavailable";
      } else if (dateEpoch > lastDateAvailable.getTime()) {
        tooltipText = "Booking season not open yet";
      } else if (datesToGrayOutEpoch.includes(dateEpoch)) {
        tooltipText = "No check-in";
      } else if (dateEpoch <= today.getTime()) {
        tooltipText = "Invalid check-in";
      } else {
        tooltipText = "Available for check-in";
      }
    } else if (currentlyShowing === "checkOut") {
      if (bookedAndNotOkayToCheckOutDates.includes(dateEpoch)) {
        tooltipText = "Unavailable";
      } else if (dateEpoch > lastDateAvailable.getTime()) {
        tooltipText = "Booking season not open yet";
      } else if (datesToGrayOutEpoch.includes(dateEpoch)) {
        tooltipText = "No check-out";
      } else if (dateEpoch <= today.getTime()) {
        tooltipText = "Invalid check-out";
      } else {
        tooltipText = "Available for check-out";
      }
    }

    return (
      <Tooltip
        hasArrow
        placement="top"
        label={tooltipText}
        aria-label={tooltipText}
      >
        <span>{day}</span>
      </Tooltip>
    );
  }

  // Trims down the Guesty data to only the parts we care about within the next year
  function transformGuestyData(guestyDataRaw, hoursOffset) {
    const lastDateAvailable = new Date(
      globalCheckIns[globalCheckIns.length - 1]
    );

    return (
      guestyDataRaw.data.days
        // Filter out dates that are past a year from now
        .filter(
          (day) => new Date(day.date).getTime() <= lastDateAvailable.getTime()
        )
        .map((day) => {
          let guestyDate = new Date(day.date);
          guestyDate.setHours(0);
          guestyDate.setMinutes(0);
          guestyDate.setSeconds(0);
          // If hoursOffset is greater than 0, add 1 day to all dates
          if (hoursOffset > 0)
            guestyDate = new Date(guestyDate.setDate(guestyDate.getDate() + 1));

          return {
            startDate: guestyDate,
            status: day.status,
            booked: day.status === "available" ? false : true,
            minDays: day.minNights,
            price: day.price,
            cta: day.cta,
            ctd: day.ctd,
          };
        })
    );
  }

  // Can only check in on a day that's *not* already book AND where CTA is false
  // AND if that date will have a valid Check Out date too
  function processCheckIns(guestyDataTransformed) {
    for (const date of guestyDataTransformed) {
      if (
        date.booked === false &&
        date.cta !== true &&
        processCheckOuts(date.startDate, guestyDataTransformed).length > 0
      ) {
        date.checkInOkay = true;
      } else {
        date.checkInOkay = false;
      }
    }

    return guestyDataTransformed;
  }

  function processCheckOuts(pickedDate, allDates) {
    const pickedDateRange = allDates.find(
      (range) => range.startDate.getTime() === pickedDate.getTime()
    );
    if (!pickedDateRange)
      throw new Error(
        `Date range not found for the picked date: ${pickedDate}`
      );

    // Sort allDates in ascending order of startDate
    allDates.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    // Find the first startDate after the pickedDate that is booked
    const firstBookedDateRange = allDates.find(
      (range) =>
        range.booked === true &&
        range.startDate.getTime() > pickedDate.getTime()
    );
    const availableDates = [];

    for (const dateRange of allDates) {
      const startDate = dateRange.startDate;
      const diffTime = startDate.getTime() - pickedDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // If the start date of the range is not booked and is at least minDays in the future or is in the past, add it to the available dates
      if (
        dateRange.booked === false &&
        startDate.getTime() >= pickedDate.getTime() &&
        diffDays >= pickedDateRange.minDays &&
        dateRange.ctd !== true
      ) {
        // Stop adding to availableDates if the date is later than the first booked date
        if (
          firstBookedDateRange &&
          startDate.getTime() >= firstBookedDateRange.startDate.getTime()
        )
          break;

        availableDates.push(startDate);
      }
    }

    // Add the first booked startDate after the pickedDate as checkout on this date should be allowed
    if (
      firstBookedDateRange &&
      Math.ceil(
        (firstBookedDateRange.startDate.getTime() - pickedDate.getTime()) /
          (1000 * 60 * 60 * 24)
      ) >= pickedDateRange.minDays
    ) {
      availableDates.push(firstBookedDateRange.startDate);
    }

    //console.log(availableDates);
    return availableDates;
  }

  return (
    <Flex
      mx={"auto"}
      direction="column"
      w="100%"
      maxW="100vw"
      alignItems={"left"}
    >
      {isLoading === true && <Spinner />}

      {isLoading === false && (
        <Flex>
          <Box
            border={"solid 1px #CCC"}
            borderRight={"0px"}
            w="48px"
            h="46px"
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            p={2}
          >
            <Icon
              as={FaHome}
              color="gray.500"
              h="100%"
              w="100%"
              px="1px"
              ml="1px"
            />
          </Box>

          <Box border={"solid 1px #CCC"} w={"100%"}>
            <DatePicker
              placeholderText="Select Dates"
              h={"100%"}
              isClearable={true}
              selectsRange={true}
              startDate={startDatePicker}
              endDate={endDatePicker}
              openToDate={
                startDatePicker ||
                (firstAvailableCheckIn && firstAvailableCheckIn.startDate)
              }
              onChange={(update) => {
                //console.log("***** onChange")
                //setDateRange(update)
                setDateRange(update);
                if (update && update.length > 0 && update[0] !== null)
                  setStart(update[0]);
                if (update && update.length > 1 && update[1] !== null)
                  setEnd(update[1]);
                //console.log("update", update)

                // Gray out bad check in dates either: (1) on first load (when both are null) *or* (2) when both are NOT null
                // (So the user can't click on a bad check in date when a date range is already selected)
                const grayOutBadCheckInDates =
                  (update[0] === null && update[1] === null) ||
                  (update[0] !== null && update[1] !== null);
                // Gray out bad check out dates when a check out date hasn't been selected yet
                const grayOutBadCheckOutDates =
                  update[0] !== null && update[1] === null;

                if (grayOutBadCheckOutDates) {
                  setCurrentlyShowing("checkOut");
                  // Set the datepicker to gray out dates that can't be checked OUT
                  // First, find which dates ARE okay to check out for the user-selected check in date (update[0])
                  const datesOkayToCheckOut = processCheckOuts(
                    update[0],
                    guestyData
                  );
                  //console.log("datesOkayToCheckOut", datesOkayToCheckOut)
                  // Create an array of all the dates NOT in that array (ie: the ones that are NOT okay to check out)
                  const datesNotOkayToCheckOut = guestyData
                    .filter(
                      (date) => !datesOkayToCheckOut.includes(date.startDate)
                    )
                    .map((date) => date.startDate);
                  const datesNotOkayToCheckOutEpoch = guestyData
                    .filter(
                      (date) => !datesOkayToCheckOut.includes(date.startDate)
                    )
                    .map((date) => Date.parse(date.startDate));
                  //console.log("datesNotOkayToCheckOut", datesNotOkayToCheckOut)
                  // Set those dates (the ones NOT okay to check out) to be grayed out
                  setDatesToGrayOut(datesNotOkayToCheckOut);
                  setDatesToGrayOutEpoch(datesNotOkayToCheckOutEpoch);

                  // We have to create a new array to find the dates that are booked AND not okay to check out because datesNotOkayToCheckOut is dates only, do extra data
                  const datesNotOkayToCheckOutWithExtraData = guestyData.filter(
                    (date) => !datesOkayToCheckOut.includes(date.startDate)
                  );
                  const getBookedAndNotOkayToCheckOutDates =
                    datesNotOkayToCheckOutWithExtraData
                      .filter((date) => date.status === "booked")
                      .map((date) => Date.parse(date.startDate));
                  setBookedAndNotOkayToCheckOutDates(
                    getBookedAndNotOkayToCheckOutDates
                  );
                } else if (grayOutBadCheckInDates) {
                  setCurrentlyShowing("checkIn");
                  // Set the datepicker to gray out dates that can't be checked IN (basically same thing as what it does onload)
                  setDatesToGrayOut(datesNotOkayToCheckIn);
                  setDatesToGrayOutEpoch(datesNotOkayToCheckInEpoch);
                }
              }}
              dayClassName={(date) => {
                // If a check in date has NOT been picked yet, set ALL booked dates to hash background
                // If a check in date HAS been picked (so we're currently showing check out dates), only set booked dates that are *also* NOT okay to check out on to hash background
                if (currentlyShowing === "checkIn")
                  return bookedDates.includes(Date.parse(date))
                    ? "datepicker-date-booked"
                    : undefined;
                if (currentlyShowing === "checkOut")
                  return bookedAndNotOkayToCheckOutDates.includes(
                    Date.parse(date)
                  )
                    ? "datepicker-date-booked"
                    : undefined;
              }}
              excludeDates={datesToGrayOut}
              renderDayContents={renderDayContents}
              includeDateIntervals={[
                {
                  start: new Date(),
                  end: addDays(new Date(), 366),
                },
              ]}
            />
          </Box>
        </Flex>
      )}

      <Flex direction="column" minH="325px" py={3} fontSize={"16px"}>
        <Flex justifyContent={"space-between"} pt={{ base: 0, md: 3 }}>
          <Flex fontWeight={"700"}>Subtotal:</Flex>
          <Flex fontWeight={"700"}>
            {price
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(price)
              : ""}
          </Flex>
        </Flex>
        <Flex justifyContent={"space-between"} pt={1}>
          <Flex>Estimated Taxes and Fees:</Flex>
          <Flex>
            {price
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(taxes + fees)
              : ""}
          </Flex>
        </Flex>
        <Divider py={1} opacity={1} />
        <Flex justifyContent={"space-between"} pt={1}>
          <Flex fontWeight={"700"}>Estimated Total:*</Flex>
          <Flex fontWeight={"700"}>
            {price
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(price + fees + taxes)
              : ""}
          </Flex>
        </Flex>
        <Flex textAlign={"start"} py={4}>
          * Total will be finalized during checkout when you continue to
          booking.
        </Flex>
        {end && !price && price !== null && <Spinner />}
        {price !== null ? (
          price > 0 && (
            <>
              <Button
                onClick={() => {
                  if (start && end && listingId) {
                    const formattedStart = format(start, "yyyy-MM-dd");
                    const formattedEnd = format(end, "yyyy-MM-dd");
                    //window.open(`${baseUrl}/${houseNumber}?guests=2&startdate=${formattedStart}&enddate=${formattedEnd}`);
                    navigate(
                      `/${houseNumber}/payment?rebook=guestportalrebook`,
                      { state: { reservationQuote: reservationQuote } }
                    );
                    modalClose();
                  }
                }}
                className="dm-button-orange-pill rebook-redirect-booking-site"
                w={{ base: "100%", lg: "100%" }}
                boxShadow="base"
              >
                Continue to Booking
              </Button>
              <Button
                mt={3}
                onClick={() => {
                  setDate(() => "");
                  setStart(() => null);
                  setEnd(() => null);
                  setPrice(0);
                }}
                className="dm-button-navy-outline"
                w={{ base: "100%", lg: "100%" }}
                boxShadow="base"
              >
                Clear
              </Button>
            </>
          )
        ) : (
          <>
            <Box color={"dmOrange"} fontWeight={"500"}>
              Sorry, these dates are not available for booking yet. Please
              select an earlier week or try again later.
            </Box>
            <Button
              mt={3}
              onClick={() => {
                setDate(() => "");
                setStart(() => null);
                setEnd(() => null);
                setPrice(0);
              }}
              className="dm-button-navy-outline"
              w={{ base: "100%", lg: "100%" }}
              boxShadow="base"
            >
              Clear results
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export { AvailabilityCalendar };

